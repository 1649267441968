<template>
    <svg viewBox="0 0 37 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M36.9553 0V32.3255H29.009V12.939L21.7083 32.3255H15.2942L7.94631 12.8923V32.3255H0V0H9.38906L18.5472 22.385L27.6109 0H36.9553Z" fill="currentColor"/>
        <path d="M36.9553 45.7663H0V37.8824H36.9553V45.7663Z" fill="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: "MSeul"
}
</script>

<style scoped>

</style>
